function App() {
  const hl = {
    backgroundImage: "url('images/hero-bg.jpg')",
  };
  const hll = {
    marginRight: "15px",
  };

  return (
    <div className="App">
      {/*<!-- Loader -->*/}
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>

      {/*<!-- Loader -->*/}
      {/*<!-- Navbar Start --> */}
      <nav
        id="navbar"
        className="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky"
      >
        <div className="container">
          {/*<!-- Logo container-->*/}
          <a className="logo navbar-brand" href="#">
            Glimar Azuaje
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </button>
          {/*<!--end button-->*/}

          <div
            className="collapse navbar-collapse navigation"
            id="navbarCollapse"
          >
            <ul
              id="navbar-navlist"
              className="navbar-nav navbar-nav-link ms-auto"
            >
              <li className="nav-item">
                <a className="nav-link active" href="#inicio">
                  Inicio
                </a>
              </li>
              {/*<!--end nav item-->*/}
              <li className="nav-item">
                <a className="nav-link" href="#servicios">
                  Servicios
                </a>
              </li>
              {/*<!--end nav item-->*/}
              <li className="nav-item">
                <a className="nav-link" href="#contacto">
                  Contacto
                </a>
              </li>
              {/*<!--end nav item-->*/}
            </ul>

            <ul className="top-right text-right list-unstyled list-inline mb-0 mt-2 mt-sm-0 nav-social">
              <li className="list-inline-item me-2">
                <a href="https://wa.me/+584265447014">
                  <i className="mdi mdi-whatsapp"></i>
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a href="mailto:glimarcarolinazuaje@gmail.com">
                  <i className="mdi mdi-email"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/*<!--end container-->*/}
      </nav>
      {/*<!--end navbar-->*/}
      {/*<!-- Navbar End -->*/}
      {/*<!-- Hero Area -->*/}

      <div
        className="hero-area position-relative bg-half-120"
        style={hl}
        id="inicio"
      >
        <div className="bg-overlay"></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 align-items-center text-center">
              <div className="title-heading mt-4 pb-60">
                <img
                  src="images/pal6.png"
                  className="img-fluid rounded-circle"
                  alt=""
                />
                <h1 className="heading font-36 text-white mt-4">
                  Glimar Azuaje
                </h1>
                <h6 className="designation mb-3 text-white">
                  Profesora en
                  <span className="text-primary"> Educación Integral</span>
                </h6>
                <div className="hero-btn mt-4 pt-2">
                  <a href="#contacto" className="btn btn-primary" style={hll}>
                    Hablemos
                  </a>
                  <a href="#servicios" className="btn btn-primary">
                    Servicios
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!--end section -->*/}
      {/*<!-- Service Start -->*/}
      <section className="section bg-light" id="servicios">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="container-title text-center mb-4 pb-2">
                <div className="titles">
                  <h2 className="title text-capitalize mb-4">Mis Servicios</h2>
                  <p className="pera-title para-desc-600 text-light-muted mb-0 mx-auto">
                    Acompañamiento y planes para mejorar el aprendizaje de los
                    alumnos.
                  </p>
                </div>
              </div>
            </div>
            {/*<!--end col-->*/}
          </div>
          {/*<!--end row-->*/}

          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div className="feature-widget text-left rounded">
                <div className="services-icon text-primary mb-3">
                  <img src="images/image.png" height="35" alt="" />
                </div>
                <div className="services-texts">
                  <h3 className="title mb-3">Apoyo al estudio</h3>
                  <p className="text-light-muted mb-4">
                    Actividades dirigidas a alumnos que necesitan un apoyo y
                    refuerzo extra.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div className="feature-widget text-left rounded">
                <div className="services-icon text-primary mb-3">
                  <img src="images/image1.png" height="35" alt="" />
                </div>
                <div className="services-texts">
                  <h3 className="title mb-3">Reforzamiento matemático</h3>
                  <p className="text-light-muted mb-4">
                    Habilidades para resolver operaciones matemáticas.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div className="feature-widget text-left rounded">
                <div className="icon services-icon text-primary mb-3">
                  <img src="images/image4.png" height="35" alt="" />
                </div>
                <div className="services-texts">
                  <h3 className="title mb-3">Lectoescritura</h3>
                  <p className="text-light-muted mb-4">
                    Estrategias y ejercicios para estimular los procesos de
                    lectura y escritura.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*<!--end row-->*/}
        </div>
      </section>
      {/*<!-- End Start -->*/}
      {/*<!-- Contact Start -->*/}
      <section className="section bg-white pb-0" id="contacto">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="container-title text-center mb-4 pb-2">
                <div className="titles">
                  <h2 className="title text-capitalize mb-4">Contáctame</h2>
                </div>
              </div>
            </div>
            {/*<!--end col-->*/}
          </div>
          {/*<!--end row-->*/}

          <div className="row">
            <div className="col-md-4 mt-4 pt-2">
              <div className="contact-detail text-center">
                <div className="icon">
                  <i data-feather="phone" className="fea icon-md"></i>
                </div>
                <div className="content mt-4">
                  <h5 className="title">Teléfono</h5>
                  <a
                    href="https://wa.me/+584265447014"
                    className="text-primary"
                  >
                    +58 426-5447014
                  </a>
                </div>
              </div>
            </div>
            {/*<!--end col-->*/}

            <div className="col-md-4 mt-4 pt-2">
              <div className="contact-detail text-center">
                <div className="icon">
                  <i data-feather="mail" className="fea icon-md"></i>
                </div>
                <div className="content mt-4">
                  <h5 className="title">Correo</h5>
                  <a
                    href="mailto:glimarcarolinazuaje@gmail.com"
                    className="text-primary"
                  >
                    glimarcarolinazuaje@gmail.com
                  </a>
                </div>
              </div>
            </div>
            {/*<!--end col-->*/}

            <div className="col-md-4 mt-4 pt-2">
              <div className="contact-detail text-center">
                <div className="icon">
                  <i data-feather="map-pin" className="fea icon-md"></i>
                </div>
                <div className="content mt-4">
                  <h5 className="title">Dirección</h5>
                  <p className="map-popup-view text-primary">
                    El Valle, Caracas, Distrito Capital.
                  </p>
                </div>
              </div>
            </div>
            {/*<!--end col-->*/}
          </div>
          {/*<!--end row-->*/}
        </div>
        {/*<!--end container-->*/}
      </section>
      {/*<!--end section-->*/}
      <section className="section bg-white pt-5">
        {/*<!--end container-->*/}
      </section>
      {/*<!--end section-->*/}
      {/*<!-- Contact End -->*/}
      {/*<!-- Footer Start -->*/}
      <footer className="footer footer-bar bg-black">
        <div className="container text-foot text-center">
          <p className="mb-0 text-white-50">
            © {1900 + new Date().getYear()} Glimar Azuaje.
          </p>
        </div>
        {/*<!--end container-->*/}
      </footer>
      {/*<!--end footer-->*/}
      {/*<!-- Footer End -->*/}
    </div>
  );
}

export default App;
